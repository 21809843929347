.main-theme {
  color: var(--main-bg-color);
}
.dark-bg {
  background-color: var(--main-bg-color);
}
.muted-bg {
  background-color: var(--muted-bg-color);
}
.light-bg {
  background-color: var(--light-bg-color);
}
.dark-text {
  color: var(--dark-text);
}
.light-text {
  color: var(--white-text);
}
.success-text {
  color: var(--success-bg);
}

.border-right {
  border-right: 1px solid;
}
.border-main-theme {
  border: 1px solid var(--main-bg-color);
}
.border-main-theme-color-only {
  border-color: var(--main-bg-color);
}
.border-soccer-yellow-card {
  border-color: #ffff00;
}
.border-soccer-red-card {
  border-color: #ff0000;
}
.border-horizontal {
  border-color: var(--main-bg-color);
  border-top: 1px solid;
  border-bottom: 1px solid;
}

.row-separator {
  border-color: var(--main-bg-color);
  /* border-bottom: 1px dashed var(--main-bg-color); */
  margin-top: 3px;
  margin-bottom: 5px;
  margin-left: 20px;
  margin-right: 20px;
}

.pop-box {
  border: 1px solid var(--main-bg-color);
  border-radius: 5px;
  background-color: var(--light-bg-color);
  color: var(--dark-text);
  padding: 10px;
  margin: 10px;
}
.pop-box-hover:hover {
  background-color: var(--main-bg-color);
  color: var(--white-text);
}

.main-logo {
  color: var(--main-bg-color);
  font-family: "Monofett";
  font-size: 55px;
  text-align: center;
}

.badge-info {
  background-color: var(--main-bg-color);
}
.bg-info {
  background-color: var(--main-bg-color) !important;
  color: var(--white-text);
}
.btn-info {
  background-color: var(--main-bg-color);
  border-color: var(--minus10-main-bg-color);
}
.btn-info:hover {
  background-color: var(--minus10-main-bg-color);
}
.btn-info:focus,
.btn-info.focus {
  background-color: var(--main-bg-color);
  border-color: var(--main-bg-color);
  box-shadow: 0 0 0 0.2rem var(--minus10-main-bg-color);
}
.btn-info.disabled,
.btn-info:disabled {
  background-color: var(--minus20-main-bg-color);
}
.btn-info:not(:disabled):not(.disabled):active,
.btn-info:not(:disabled):not(.disabled).active,
.show > .btn-info.dropdown-toggle {
  color: var(--white-text);
  background-color: var(--minus10-main-bg-color);
  border-color: var(--minus10-main-bg-color);
}
.btn-info:not(:disabled):not(.disabled):active:focus,
.btn-info:not(:disabled):not(.disabled).active:focus,
.show > .btn-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem var(--minus10-main-bg-color);
}

.bg-light {
  background-color: var(--light-bg-color) !important;
  color: var(--main-bg-color);
}
.bg-muted {
  background-color: var(--muted-bg-color) !important;
  color: var(--main-bg-color);
}
.btn-light {
  background-color: var(--minus10-light-bg-color);
  border-color: var(--minus20-light-bg-color);
  color: var(--main-bg-color);
}
.btn-light:hover {
  background-color: var(--minus20-light-bg-color);
  color: var(--white-text);
}
.btn-light:focus,
.btn-light.focus {
  background-color: var(--minus20-light-bg-color);
  border-color: var(--minus20-light-bg-color);
  box-shadow: 0 0 0 0.2rem var(--minus20-light-bg-color);
  color: var(--white-text);
}
.btn-light.disabled,
.btn-light:disabled {
  background-color: var(--minus20-light-bg-color);
  color: var(--dark-text);
}
.btn-light:not(:disabled):not(.disabled):active,
.btn-light:not(:disabled):not(.disabled).active,
.show > .btn-light.dropdown-toggle {
  color: var(--white-text);
  background-color: var(--minus20-light-bg-color);
  border-color: var(--minus20-light-bg-color);
  color: var(--main-bg-color);
}
.btn-light:not(:disabled):not(.disabled):active:focus,
.btn-light:not(:disabled):not(.disabled).active:focus,
.show > .btn-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem var(--minus20-light-bg-color);
  color: var(--main-bg-color);
}

.bg-primary {
  background-color: var(--primary-bg) !important;
}
.btn-primary {
  background-color: var(--primary-bg);
  border-color: var(--primary-minus10);
}
.btn-primary:hover {
  background-color: var(--primary-minus10);
  border-color: var(--primary-minus10);
}
.btn-primary:focus,
.btn-primary.focus {
  background-color: var(--primary-bg);
  border-color: var(--primary-bg);
  box-shadow: 0 0 0 0.2rem var(--primary-minus10);
}
.btn-primary:active:focus {
  background-color: var(--primary-minus10);
}
.btn-primary.disabled,
.btn-primary:disabled {
  background-color: var(--primary-minus20);
  border-color: var(--primary-minus20);
}
.btn-primary:not(:disabled):not(.disabled):active,
.btn-primary:not(:disabled):not(.disabled).active,
.show > .btn-primary.dropdown-toggle {
  color: var(--white-text);
  background-color: var(--primary-minus10);
  border-color: var(--primary-minus10);
}
.btn-primary:not(:disabled):not(.disabled):active:focus,
.btn-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem var(--primary-minus10);
}

.bg-secondary {
  background-color: var(--secondary-bg-color) !important;
  color: var(--white-text);
}
.btn-secondary {
  background-color: var(--secondary-bg-color);
  border-color: var(--main-bg-color);
  color: var(--white-text);
}
.btn-secondary:hover {
  background-color: var(--minus10-secondary-bg-color);
  color: var(--white-text);
}
.btn-secondary:focus,
.btn-secondary.focus {
  background-color: var(--minus10-secondary-bg-color);
  border-color: var(--main-bg-color);
  box-shadow: 0 0 0 0.2rem var(--main-bg-color);
  color: var(--white-text);
}
.btn-secondary.disabled,
.btn-secondary:disabled {
  background-color: var(--minus20-secondary-bg-color);
  color: var(--dark-text);
}
.btn-secondary:not(:disabled):not(.disabled):active,
.btn-secondary:not(:disabled):not(.disabled).active,
.show > .btn-secondary.dropdown-toggle {
  color: var(--white-text);
  background-color: var(--minus10-secondary-bg-color);
  border-color: var(--main-bg-color);
  color: var(--white-text);
}
.btn-secondary:not(:disabled):not(.disabled):active:focus,
.btn-secondary:not(:disabled):not(.disabled).active:focus,
.show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem var(--minus10-secondary-bg-color);
  color: var(--white-text);
}

.bg-success {
  background-color: var(--success-bg) !important;
  color: var(--white-text);
}
.btn-success {
  background-color: var(--success-bg); /* darkest */
  border-color: var(--success-minus10); /* medium */
  color: var(--white-text);
}
.btn-success:hover {
  background-color: var(--success-minus10);
  border-color: var(--success-minus10);
  color: var(--white-text);
}
.btn-success:focus,
.btn-success.focus {
  background-color: var(--success-bg);
  border-color: var(--success-bg);
  box-shadow: 0 0 0 0.2rem var(--success-minus10);
  color: var(--white-text);
}
.btn-success:active:focus {
  background-color: var(--success-minus10);
  color: var(--white-text);
}
.btn-success.disabled,
.btn-success:disabled {
  background-color: var(--success-minus20); /* lightest */
  border-color: var(--success-minus20);
  color: var(--white-text);
}
.btn-success:not(:disabled):not(.disabled):active,
.btn-success:not(:disabled):not(.disabled).active,
.show > .btn-success.dropdown-toggle {
  color: var(--white-text);
  background-color: var(--success-minus10);
  border-color: var(--success-minus20);
}
.btn-success:not(:disabled):not(.disabled):active:focus,
.btn-success:not(:disabled):not(.disabled).active:focus,
.show > .btn-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem var(--success-minus20);
  color: var(--white-text);
}

.btn-dark {
  background-color: var(--dark-bg-color); /* darkest */
  border-color: var(--dark-minus20); /* medium */
  color: var(--white-text);
}
.btn-dark:hover {
  background-color: var(--dark-minus20);
  border-color: var(--dark-minus20);
  color: var(--white-text);
}
.btn-dark:focus,
.btn-dark.focus {
  background-color: var(--dark-bg-color);
  border-color: var(--dark-bg-color);
  box-shadow: 0 0 0 0.2rem var(--dark-minus20);
  color: var(--white-text);
}
.btn-dark:active:focus {
  background-color: var(--dark-minus20);
  color: var(--white-text);
}
.btn-dark.disabled,
.btn-dark:disabled {
  background-color: var(--dark-minus20); /* lightest */
  border-color: var(--dark-minus20);
  color: var(--white-text);
}
.btn-dark:not(:disabled):not(.disabled):active,
.btn-dark:not(:disabled):not(.disabled).active,
.show > .btn-dark.dropdown-toggle {
  color: var(--white-text);
  background-color: var(--dark-minus20);
  border-color: var(--dark-minus20);
}
.btn-dark:not(:disabled):not(.disabled):active:focus,
.btn-dark:not(:disabled):not(.disabled).active:focus,
.show > .btn-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem var(--dark-minus20);
  color: var(--white-text);
}

.btn-href-link {
  background: none !important;
  border: none;
  padding: 0 !important;
  color: var(--main-bg-color);
  cursor: pointer;
}
.btn-href-link:hover {
  text-decoration: underline;
}

.info-text {
  color: var(--main-bg-color);
}
.soccer-yellow-card-text {
  color: #ffff00;
}
.soccer-red-card-text {
  color: #ff0000;
}
.soccer-yellow-card {
  background-color: #ffff00;
  color: var(--main-bg-color);
}
.soccer-yellow-card-bg {
  background-color: #ffff00;
  color: var(--main-bg-color);
}
.soccer-yellow-card:hover {
  background-color: #e6e600;
  color: var(--main-bg-color);
}
.soccer-red-card {
  background-color: #ff0000;
  color: var(--white-text);
}
.soccer-red-card-bg {
  background-color: #ff0000;
  color: var(--white-text);
}
.soccer-red-card:hover {
  background-color: #cc0000;
  color: var(--white-text);
}

.color-border {
  border: 1px solid var(--main-bg-color);
}

.clear-button {
  position: relative;
  right: 50px;
  top: 20px;
  border-radius: 100px;
  background-color: white;
  color: black;
  border: none;
}
.clear-button:hover {
  background-color: #f2f2f2;
  color: #00802b;
}
.clear-button:active {
  border: none;
}

.clink {
  cursor: pointer;
}
.clink:hover {
  text-decoration: underline;
  color: var(--main-bg-color);
}
.clink:focus {
  text-decoration: underline;
  color: var(--main-bg-color);
}

.custom-select-item {
  border: 2px solid var(--main-bg-color);
  background-color: var(--light-bg-color);
  color: var(--dark-text);
  border-radius: 25px;
  padding-right: 20px;
  padding-left: 20px;
  width: 100%;
}
.custom-select-item-selected {
  border: 2px solid var(--main-bg-color);
  border-radius: 25px;
  background-color: var(--main-bg-color);
  color: var(--white-text);
  padding-right: 20px;
  padding-left: 20px;
  font-weight: bold;
  width: 100%;
}
.custom-select-item-selected:focus {
  outline: 0;
}
.custom-select-item-selected:active {
  outline: 0;
}
.custom-select-item:focus {
  outline: 0;
}
.custom-select-item:hover {
  outline: 0;
  background-color: var(--minus10-light-bg-color);
}
.custom-select-item:active {
  outline: 0;
}

.icon-basic {
  color: black;
  vertical-align: middle;
}
.icon-sm {
  height: 15px;
  width: 15px;
}
.icon-md {
  height: 25px;
  width: 25px;
}
.icon-lg {
  height: 35px;
  width: 35px;
}
.icon-info {
  color: var(--main-bg-color);
  background-color: #fff;
  border-radius: 5px;
}
.icon-padding {
  padding: 5px;
}
.icon-info-rev {
  color: #fff;
  background-color: var(--main-bg-color);
  border-radius: 5px;
}
.icon-raised {
  margin-bottom: 5px;
  color: var(--main-bg-color);
}
.icon-feature {
  color: var(--main-bg-color);
  background-color: #fff;
  border-radius: 10px;
}
.icon-mobile {
  color: var(--main-bg-color);
  background-color: transparent;
  border-radius: 5px;
}
.icon-light {
  color: var(--white-text);
}
.icon-check {
  color: var(--light-bg-color);
  cursor: pointer;
}
.icon-check.disabled {
  cursor: default;
  color: var(--minus20-light-bg-color);
}
.icon-centered {
  margin: auto;
  margin-top: 10px;
  width: 100%;
}

.dark-border {
  border-color: var(--dark-bg-color);
}
.custom-dark-text {
  color: var(--dark-bg-color);
}
.info-border {
  border-color: var(--main-bg-color);
}

.password-eye {
  margin-left: 15px;
  align-self: center;
}
.pw-invisible {
  color: var(--main-bg-color);
}
.pw-visible {
  color: var(--minus20-main-bg-color);
}

.navbar-title {
  margin-left: 15px;
  margin-right: 15px;
  border-radius: 5px;
  background-color: var(--main-bg-color);
  color: var(--white-text);
  border-radius: 3px;
  width: 100%;
  display: flex;
  padding: 1px;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.nav-link {
  color: var(--main-bg-color);
  font-weight: bold;
}
.nav-link:active {
  background-color: var(--main-bg-color);
  color: var(--white-text);
}
.nav-item:active {
  background-color: var(--main-bg-color);
  color: var(--white-text);
}
.nav-item:hover {
  color: var(--main-bg-color);
}
.nav-item:focus {
  background-color: var(--main-bg-color);
  color: var(--white-text);
}
