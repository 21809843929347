.checkbox-wrapper input[type="checkbox"] {
  /* removing default appearance */
  -webkit-appearance: none;
  appearance: none;
  /* creating a custom design */
  width: 1.2em;
  height: 1.2em;
  border-radius: 0.15em;
  margin-right: 0.5em;
  border: 0.15em solid var(--main-bg-color);
  outline: none;
  cursor: pointer;
}
.checkbox-wrapper input[type="checkbox"]:disabled {
  border-color: var(--main-bg-color);
  background-color: var(--minus10-light-bg-color);
  cursor: default;
}

.checkbox-wrapper input[type="checkbox"]:focus {
  box-shadow: 0 0 10px var(--minus10-main-bg-color);
}

input:checked {
  background-color: var(--main-bg-color);
  position: relative;
}

input:checked::before {
  font-size: 1em;
  position: absolute;
  right: 1px;
  top: -5px;
}

.centered-small-input-area {
  margin: auto;
  margin-top: 10px;
  max-width: 700px;
}

.form-multi-select {
  background-color: var(--white-text);
  color: var(--dark-text);
  max-height: 120px;
  border-radius: 2px;
  overflow: scroll;
  overflow-x: hidden;
}
.form-multi-select-option {
  padding: 5px;
  cursor: pointer;
  &:hover {
    background-color: var(--light-bg-color);
  }
  &.selected {
    background-color: var(--main-bg-color);
    color: var(--white-text);
  }
}

.api-error {
  text-align: center;
  padding: 2px;
  margin: 10px;
  border: 1px solid var(--main-bg-color);
  color: var(--main-bg-color);
  background-color: var(--muted-bg-color);
  border-radius: 3px;
}

.custom-text-area {
  border: 1px solid var(--main-bg-color);
  border-radius: 5px;
  margin: 5px;
}
.custom-text-area:focus {
  border-color: var(--main-bg-color);
}
