:root {
  --main-bg-color: #831fe0;
  --minus10-main-bg-color: #9c4be7;
  --minus20-main-bg-color: #b478ed;
  --muted-bg-color: #e6d2f9;
  --light-bg-color: #f3e9fc;
  --minus10-light-bg-color: #e6d2f9;
  --minus20-light-bg-color: #b478ed;
  --primary-bg: #733e0c;
  --primary-minus10: #a25711;
  --primary-minus20: #d07016;
  --success-bg: #00b300;
  --success-minus10: #008000;
  --success-minus20: #004d00;
  --secondary-bg-color: #666699;
  --minus10-secondary-bg-color: #8585ad;
  --minus20-secondary-bg-color: #a3a3c2;
  --dark-bg-color: #1a062d;
  --dark-minus10: #340c5a;
  --dark-minus20: #4e1287;
  --white-text: #fff;
  --dark-text: #000;
}

body {
  margin: 0;
  padding: 0 0 0 0;
  background-color: #f3e9f0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.clickable {
  cursor: pointer;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

html {
  scroll-behavior: smooth;
}

.btn-xsm {
  padding: 0.25rem 0.5rem;
  font-size: 0.675rem;
  line-height: 1;
  border-radius: 0.2rem;
}

.tooltip-wrapper {
  display: inline-block;
  position: relative;
}
.tooltip-buttons {
  position: absolute;
  padding: 6px;
  background-color: var(--light-bg-color);
  border-radius: 5px;
  border: 1px solid var(--main-bg-color);
  font-size: 14px;
  font-family: sans-serif;
  line-height: 1;
  z-index: 100;
  white-space: nowrap;
  left: auto;
  right: 20px;
  top: 50%;
  transform: translateX(0) translateY(-50%);
}
.tooltip-tip {
  position: absolute;
  border: 1px solid var(--main-bg-color);
  border-radius: 4px;
  padding: 6px;
  color: black;
  background: var(--light-bg-color);
  font-size: 14px;
  font-family: sans-serif;
  line-height: 1;
  z-index: 100;
}
.tooltip-tip.bottom {
  left: 50%;
  top: 105%;
  transform: translateX(-50%);
}
.tooltip-tip.right {
  left: 105%;
  top: -6px;
}
.tooltip-tip.left {
  right: 105%;
  top: -6px;
}
.tooltip-tip.top {
  bottom: 105%;
  transform: translateX(-50%);
}
.tooltip-tip.top-left {
  bottom: 105%;
  right: 105%;
}
.tooltip-tip.top-right {
  bottom: 105%;
  left: 105%;
}

.dropdown-selector {
  position: absolute;
  width: 80%;
  height: auto;
  border: 3px solid var(--muted-bg-color);
  border-radius: 5px;
  padding: 6px;
  margin-left: 20px;
  margin-right: 20px;
  color: black;
  background: var(--light-bg-color);
  z-index: 100;
  overflow-x: hidden;
  overflow-y: auto;
}
.dropdown-selector-item {
  margin-bottom: 10px;
  font-size: 18px;
  border-radius: 5px;
  padding-left: 10px;
  padding-right: 10px;
}
.dropdown-selector-item:hover {
  background-color: var(--muted-bg-color);
}
.dropdown-selector-item-selected {
  background-color: var(--main-bg-color);
  color: var(--white-text);
}
.dropdown-selector-item-selected:hover {
  background-color: var(--main-bg-color);
  color: var(--white-text);
}
.dropdown-selector.top {
  bottom: 100%;
}
.dropdown-selector.bottom {
  left: 50%;
  top: 0px;
  margin-top: 35px;
  transform: translateX(-50%);
}

.logo {
  max-width: 100%;
  height: auto;
}

.logo-centering {
  position: absolute;
  top: 45%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 100%;
  padding: 20px;
}

.high-z {
  z-index: 99;
}

.feature {
  border-radius: 5px;
  width: 100%;
  padding-top: 5px;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 15px;
  margin-bottom: 15px;
}

.bracket-match-spacer {
  border-right: 1px solid black;
  color: grey;
  font-size: 0.75em;
  text-align: left;
}

.bracket-top-team {
  border-bottom: 1px solid black;
  text-align: center;
  color: black;
  font-size: 1em;
}

.bracket-bottom-team {
  border-top: 1px solid black;
  text-align: center;
  color: black;
  font-size: 1em;
}

.bracket-team-top {
  border-bottom: 1px solid black;
  text-align: center;
  color: black;
  font-size: 1em;
}

.bracket-team-bottom {
  border-top: 1px solid black;
  text-align: center;
  color: black;
  font-size: 1em;
}

.bracket-team-semi {
  border-top: 1px solid black;
  text-align: center;
  color: black;
  font-size: 1em;
}

.bracket-match-spacer-left {
  border-right: 1px solid black;
  color: grey;
  font-size: 0.75em;
  justify-content: flex-start;
}

.bracket-match-spacer-right {
  border-left: 1px solid black;
  color: grey;
  font-size: 0.75em;
  justify-content: flex-end;
}

.bracket-second-chance-final {
  margin-bottom: 20px;
  border: 1px solid;
  border-radius: 5px;
}

.bracket-match-spacer-final {
  border-left: 1px solid black;
  border-right: 1px solid black;
  color: grey;
  font-size: 0.75em;
  justify-content: center;
}

.bracket-spacer {
  border-right: none;
}

.bracket-spacer-join-left {
  border-right: 1px solid black;
}

.bracket-spacer-join-right {
  border-left: 1px solid black;
}

.rotate {
  -webkit-transform: rotate(-90deg);
  -moz-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  -o-transform: rotate(-90deg);
  transform: rotate(-90deg);
}

.round-corners {
  border-radius: 5px;
}
.banner-crop {
  object-fit: cover;
}

.app-store-logo-container {
  padding: 0px;
  width: 300px;
}
.app-store-logo {
  max-width: 100%;
}
.app-store-logo.apple {
  margin: 6%;
  width: 78%;
}

hr.custom {
  border: 1px solid var(--minus20-main-bg-color);
  margin: 10px;
  margin-left: 2%;
  margin-right: 2%;
}

.row-display {
  margin-bottom: 5px;
  border-bottom: 1px solid var(--main-bg-color);
}
