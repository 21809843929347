.image-slider {
  position: relative;
  margin: auto;
  margin-bottom: 5px;
}

.slider-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: opacity 2s ease-in-out;
  border-radius: 10px;
}

.slider-image.active {
  opacity: 1;
}

.features {
  position: relative;
}
.feature-nav {
  position: sticky;
  top: 60px;
}
.feature-nav-list {
  display: flex;
  width: auto;
  border-radius: 2px;
  background-color: var(--main-bg-color);
  overflow-x: auto;
  margin-left: auto;
  margin-right: auto;
}
.feature-nav-arrow {
  position: absolute;
  right: 10px;
}
.feature-nav-item {
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin: 5px;
}
.feature-nav-link {
  color: var(--white-text);
}
.feature-nav-link:hover {
  color: var(--muted-bg-color);
  text-decoration: none;
}
.feature-nav-section {
  scroll-margin-top: 115px;
  justify-content: center;
  align-items: center;
  padding: 10px;
  color: var(--main-bg-color);
  background-color: var(--light-bg-color);
}
.feature-nav-section:nth-child(odd) {
  background-color: var(--muted-bg-color);
}
