.svg-whole-bracket {
  background-color: var(--muted-bg-color);
  border-radius: 5px;
  border: 1px solid var(--main-bg-color);
}
.svg-bracket-match {
  fill: var(--muted-bg-color);
}

.svg-link {
  fill: var(--dark-text);
  cursor: pointer;
}
.svg-link:hover {
  text-decoration: underline;
  fill: var(--main-bg-color);
}
.svg-link:focus {
  text-decoration: underline;
}

.svg-final {
  background-color: var(--main-bg-color);
  border-radius: 5px;
}
.svg-text {
  fill: var(--dark-text);
}
.svg-text-header {
  font-weight: 400;
}
.svg-bracket-line {
  stroke: var(--dark-text);
}
.svg-bracket-line-final {
  stroke: var(--main-bg-color);
}

.svg-match-spacer {
  font-size: 14px;
}
